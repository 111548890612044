import { Box } from '@material-ui/core'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ProductList,
    ProductListProps
} from 'common/components/organisms/ProductList'
import React from 'react'

export interface ToolsHubProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    listings: ProductListProps[]
    // ila: ResourcesProps
}

export const ToolsHub: React.FC<ToolsHubProps> = ({
    jumbotron,
    layout,
    listings
}) => {
    return (
        <Box bgcolor='#fff'>
            <Layout {...layout}>
                {jumbotron.title && <Jumbotron {...jumbotron} />}
                <Box
                    style={{
                        background:
                            'radial-gradient(71.23% 43.94% at 10.65% 72.43%, rgba(247, 247, 249, 0.8) 0%, rgba(247, 247, 249, 0) 100%), #EBEDF7'
                    }}
                >
                    {listings &&
                        listings.map((listing: ProductListProps) => (
                            <>
                                <ProductList {...listing} transparent={true} />
                            </>
                        ))}
                </Box>
            </Layout>
        </Box>
    )
}
