import { ProductListProps } from 'common/components/organisms/ProductList'
import { ToolsHub } from 'common/components/templates/ToolsHub'
import { useUrl1 } from 'common/hooks/useUrl1'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ToolsHubPageProps {
    pageContext: any
}

export const ToolsHubPage: React.FC<ToolsHubPageProps> = ({ pageContext }) => {
    const page = useUrl1(1)
    return <ToolsHub {...getToolsHubPageData(pageContext, page)} />
}

export default ToolsHubPage

export const getToolsHubPageData = (data: any, page: string) => ({
    layout: getLayoutData(data),
    jumbotron: {
        title: data.jumbotron__title,
        text: data.jumbotron__subtitle
    },
    codename: page,
    listings: data.multi_product_list.map(
        (product_listings: ProductListProps) => ({
            title: product_listings.title,
            text: '',
            heading: 'Tools',
            products: product_listings.products.map((item: any) => ({
                title: item.title,
                text: item.text,    
                icon: item.icon,
                callToAction: {
                    label: item.card_cta_0396ba7__cta_label,
                    url: item.card_cta_0396ba7__cta_url,
                    variant: item.card_cta_0396ba7__cta_variant
                }
            }))
        })
    )
})
