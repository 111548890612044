import { Box, Button, Grid } from '@material-ui/core'
import { Card } from 'common/components/molecules/Card'
import { CardCtaProps } from 'common/components/molecules/CardCta'
import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { useState } from 'react'
import { LeftIntro } from '../LeftIntro'

export interface ProductListProps {
    heading: string
    products: {
        callToAction: CardCtaProps
        icon?: string
        title: string
        text: string
    }[]
    singleColumn?: boolean
    text: string
    title: string
    filter?: boolean
    iconSize?: 'md' | 'lg'
    transparent?: boolean
    generic?: boolean
}

export const ProductList: React.FC<ProductListProps> = ({
    generic,
    heading,
    products = [],
    singleColumn,
    text,
    title,
    filter,
    iconSize,
    transparent
}) => {
    const desktop = useDesktop()

    const [filteredProducts, setfilteredProducts] = useState(
        filter ? products.slice(0, 6) : products
    )
    const [count, setCount] = useState(6)

    const loopWithSlice = (start: number, end: number) => {
        const slicedPosts = products.slice(start, end)
        setfilteredProducts([...slicedPosts])
    }

    const loadMore = () => {
        setCount(count + 6)
        loopWithSlice(0, count + 6)
    }

    return (
        <Box
            data-testid='ProductList'
            display='flex'
            flexDirection='column'
            style={
                transparent
                    ? {}
                    : {
                          background:
                              'radial-gradient(71.23% 43.94% at 10.65% 72.43%, rgba(247, 247, 249, 0.8) 0%, rgba(247, 247, 249, 0) 100%), #EBEDF7'
                      }
            }
            component='section'
        >
            {!generic && <Spacer variant={desktop ? 'lg' : 'md'} />}
            <Wrapper>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <LeftIntro
                            text={text}
                            title={title}
                            heading={heading}
                        />
                    </Grid>
                    <Grid item md={1} />
                    <Grid item xs={12} md={8}>
                        <Box>
                            {!desktop && <Spacer variant='md' />}
                            <Grid container spacing={6}>
                                {filteredProducts.map(
                                    (
                                        product: JSX.IntrinsicAttributes &
                                            import('../../molecules/Card').CardProps & {
                                                children?: React.ReactNode
                                            }
                                    ) => (
                                        <Grid
                                            key={product.title}
                                            item
                                            xs={12}
                                            md={singleColumn ? 12 : 6}
                                        >
                                            <Card
                                                {...product}
                                                iconSize={iconSize}
                                            />
                                        </Grid>
                                    )
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {filter && count <= products.length && (
                                        <Button
                                            variant='contained'
                                            onClick={loadMore}
                                            style={{
                                                border: '2px solid #5261AC',
                                                background: '#EBEDF7',
                                                color: '#5261AC',
                                                borderRadius: '36px',
                                                textTransform: 'none',
                                                fontSize: '16px'
                                            }}
                                        >
                                            Load more
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Wrapper>
            {!generic && <Spacer variant={desktop ? 'lg' : 'md'} />}
        </Box>
    )
}
